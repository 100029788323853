.admin {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5rem;

    & h2 {
        font-weight: lighter;
        border-bottom: 1px solid var(--main-black);
    }
    & .collection {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        gap: 0.5rem;
    }
    & .collection-header {
        display: grid !important;
    }
    & h3 {
        font-weight: lighter;
    }
    & h6 {
        font-size: 5rem;
        margin-top: -2rem;
    }
    & div {
        width: 100%;
        display: flex;
        gap: 1rem;
        align-items: center;
        & svg {
            font-size: 2rem;
            border: 1px solid var(--main-black);
            border-radius: 5rem;
            text-align: center;
        }
    }
    & .admin-add-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & svg {
            border: 0rem;
        }
    }
}

.image-gallery-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.image-gallery-extra-class {
    flex-direction: column !important;
}