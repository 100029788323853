.login {
    width: 100%;
    max-width: 500px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5rem;
}

.login form {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;   
}

.login-error-message {
    width: fit-content;
    height: fit-content;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    color: red;
    border: 1px solid red;
    padding: 1rem;
    border-radius: 0.5rem;
}

.login form input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid var(--main-black);
}

.login input[type='submit'] {
    border: 1px solid var(--main-black);
    border-radius: 5px;
}