.delete-collection {
    width: 90%;
    height: fit-content;
    min-height: 80vh;
    position: fixed;
    top: 1rem;
    left: 1.9rem;
    background-color: var(--main-black);
    color: var(--main-white);
    border-radius: 1rem;
    z-index: 1000;
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5rem;

    & form {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    & h4:last-of-type {
        color: var(--main-red);
    }
}