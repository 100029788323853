.collections {
    width: 100%;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    & h2 {
        font-weight: lighter;
        border-bottom: 1px solid var(--main-black);
    }
    & .collection {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }
    & h3 {
        font-weight: lighter;
    }
    & h6 {
        font-size: 5rem;
        margin-top: -2rem;
    }
}

.collections-header {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.collections-dropdown {
    height: 0;
    overflow: hidden;
}

.collections-dropdown.active {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    padding-left: 2rem;
    & li {
        text-decoration: underline;
    }
}

.collection-header {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
}

.collection-header span {
    width: 100%;
    height: 80%;
    display: flex;
    /* align-items: center; */
    /* background-color: red; */
}

.collection-header span:first-of-type {
    border-left: 1px solid var(--main-black)
}
.collection-header span:last-of-type {
    border-right: 1px solid var(--main-black)
}

.collection-header span div {
    width: 100%;
    height: 50%;
    border-bottom: 1px solid var(--main-black);
    /* background-color: blue; */
}

.collection-header h3 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.5rem;
}

.image-gallery-photo {
    height: 80vh;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image-gallery-slide .image-gallery-description {
    position: static !important;
}

.image-gallery-description {
    background-color: transparent !important;
    color: var(--main-black) !important;
    z-index: 100;
}

.image-gallery-bullets-extra-class {
    background-color: var(--main-black) !important;
    box-shadow: 0 0 0 !important;
    margin: 1rem !important;
}

.image-gallery-bullets {
    position: static !important;
}

.image-gallery-bullet.active {
    border: 1px solid var(--main-black) !important;
}