footer {
    width: 100%;
    height: fit-content;
    min-height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

footer button {
    border-bottom: 1px solid black;
    border-radius: 0;
    padding: 0;
}

footer div {
    width: 100%;
    display: flex;
    gap: 2rem;
    justify-content: center;
}