header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    background-color: var(--main-white);
    padding: 1rem;

    & h1 {
        font-weight: lighter;
        font-size: 2.5rem;
    }

    & .menu-control {
        width: 3rem;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5rem;
        position: absolute;
        right: 2rem;
    }

    & .menu-control span {
        width: 100%;
        height: 0.5rem;
        border-radius: 0.5rem;
        background-color: var(--main-grey);
        transition: 0.5s;
        z-index: 200;
    }

    & .menu-control.active span:first-of-type {
        transform: rotate(45deg) translateY(0.7rem) translateX(0.7rem);
    }
     
    & .menu-control.active span:nth-of-type(2) {
        width: 0rem;
        transform: translateX(1.5rem);
    }

    & .menu-control.active span:nth-of-type(3) {
        transform: rotate(-45deg) translateY(-0.7rem) translateX(0.7rem);
    }

    & .side-menu {
        width: 100vw;
        height: fit-content;
        min-height: 30vh;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-100vw);
        transition: 0.5s;
        z-index: 100;
        background-color: var(--main-white);
        color: var(--main-black);
        padding: 1rem;
        box-shadow: 0 0 1rem var(--main-black);
    }

    & .side-menu.active {
        transform: translateY(0vw);
    }

    & .side-menu-container {
        visibility: hidden;
        transition: 0.5s;
    }

    & .side-menu-container.active {
        visibility: visible;
        display: flex;
        width: 100vw;
        height: 100vh;
        background-color: rgba(128, 128, 128, 0.5);
        position: fixed;
        left: 0;
    }

    & .side-menu-header {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 4rem;
    }
    
    & .side-menu-header h2 {
        font-weight: lighter;
        border-bottom: 1px solid var(--main-black);
    }
}
