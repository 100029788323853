.edit-photo {
    width: 90%;
    height: fit-content;
    min-height: 80vh;
    position: fixed;
    top: 1rem;
    left: 1.9rem;
    background-color: var(--main-black);
    border-radius: 1rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1rem;

    & p {
        color: var(--main-white);
    }

    & .image-upload-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & svg {
        color: red;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }

    & form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    & .edit-photo-page-1,
    .edit-photo-page-2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
    & .error {
        border: 1px solid red;
    }

    & .flash-container {
        display: flex;
        gap: 1rem;
    }

    & .fieldset-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    & fieldset {
        width: 50%;
        padding: 1rem;
    }

    & select {
        width: 100%;
    }

    & .delete-photo {
        background-color: var(--main-red) !important;
    }

    & .toggle-delete-photo  {
        display: none;
    }

    & .toggle-delete-photo.active {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}