.App {
  width: 100vw;
  min-width: 100vw;
  max-width: 100vw;
  height: fit-content;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Lora';
  background-color: var(--main-white);
  color: var(--main-black);
  padding: 0 1rem;
  font-size: 1.5rem;
}


.add-photo-container.active,
.edit-photo-container.active,
.add-collection-container.active,
.edit-collection-container.active,
.delete-collection-container.active {
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.281);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}