html {
  font-size: 10px;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
}

:root {
  --main-white: rgb(228, 228, 228);
  --main-black: rgb(23, 23, 23);
  --main-grey: rgb(54, 54, 54);
  --main-blue: #085cd4;
  --main-red: rgb(194, 9, 9);
}

input {
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 5px;
  border: 0;
}

input[type='submit'] {
  max-width: 40%;
}

.mobile-button-styler {
  width: 40% !important;
  height: fit-content !important;
  display: flex !important;
  justify-content: center !important;
  padding: 0.7rem 0 !important;
  background-color: var(--main-blue) !important;
  color: var(--main-white) !important;
  font-weight: bolder !important;
  border: 0 !important;
  border-radius: 1rem !important;
}

.mobile-button-delete-styler {
  width: 40% !important;
  height: fit-content !important;
  display: flex !important;
  justify-content: center !important;
  padding: 0.7rem 0 !important;
  background-color: var(--main-red) !important;
  color: var(--main-white) !important;
  font-weight: bolder !important;
  border: 0 !important;
  border-radius: 1rem !important;
}

button {
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 5px;
  border: 0;
  color: var(--main-black);
}

input,
select,
option,
textarea {
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--main-white);
  background-color: var(--main-white);
  color: var(--main-black);
  font-family: 'Lora';
  font-weight: bolder;
}

textarea {
  padding: 1rem;
}

label {
  width: 100%;
}

form {
  color: var(--main-white);
}

li {
  list-style: none;
}